import React from "react"

const AboutPersonalBlock = ({ data }) => {
  if (data.length <= 0) {
    return null
  }
  return (
    <section className="about-personal-area">
      <div className="container">
        <div className="row">
          {data.map((item, idx) => {
            return (
              <div className="col-md-4 col-sm-6" key={`${item?.title ?? "about-personal"}-${idx}`}>
                <div className="about-personal-item">
                  {item.image && (
                    <div className="zoom">
                      <img
                        src={item.image ? item.image.src : ""}
                        height="216px"
                        style={{
                          backgroundColor: item.feedImage ? "none" : "#A7A8AA",
                        }}
                        alt={item.title}
                      />
                    </div>
                  )}
                  <div className="about-personal-item-content">
                    <h6>{item.name}</h6>
                    <a
                      href={`mailto:${item.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.email}
                    </a>
                    <p>{item.title}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default AboutPersonalBlock
